import React from "react"
import { graphql } from "gatsby"
import classNames from 'classnames'

import Layout from '../../components/layout/layout'
import PoemHeader from '../../components/poemHeader/poemHeader'

import styles from "./titleCard.module.scss"

function TitleCard({
  data, // this prop will be injected by the GraphQL query below.
  pageContext
}) {
  const { markdownRemark } = data // data.markdownRemark holds our post data
  const { frontmatter } = markdownRemark

  return (
    <Layout
      className={ classNames(styles.titleCard, styles[pageContext.variant]) }
    >
      <PoemHeader
        className={ classNames(styles.poemHeader, styles[pageContext.variant]) }
        title={ frontmatter.title ? frontmatter.title : '{ Untitled }' }
        titleFontSize={ frontmatter.titleFontSize }
        titleClassName="h1"
        titleTag="h1"
        variant="light"
      />
    </Layout>
  )
}

export default TitleCard

export const query = graphql`
  query($path: String!) {
    markdownRemark(fields: { titleCard: { eq: $path } }) {
      html
      fields { titleCard }
      frontmatter {
        contentFontSize
        date(formatString: "YYYY · MM · DD")
        excerpt
        excerptFontSize
        title
        titleFontSize
      }
    }
  }
`
